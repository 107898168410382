import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Event, NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  private baseTitle = ' - Echo Management Portal';

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private title: Title) {}

  ngOnInit() {
    // set the page title from the route's data
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        let child = this.activatedRoute.firstChild;
        let title = '';
        while (child) {
          if (child.firstChild) {
            child = child.firstChild;
          } else if (child.snapshot.data && child.snapshot.data['title']) {
            title = child.snapshot.data['title'];
            break;
          } else {
            title = '';
            break;
          }
        }

        this.title.setTitle(title + this.baseTitle);
      }
    });
  }
}
