import { Injectable } from '@angular/core';
import { AppStorage } from 'src/app/utils/app-storage';
import { Environment } from 'src/environments/environment';
import { UserAuthService } from './eightysix-api/user-auth-service';
import { ServiceWorkerUpdateService } from './service-worker-update-service';

@Injectable({
  providedIn: 'root',
})
export class SetupService {
  constructor(private authService: UserAuthService, private appStorage: AppStorage, private updateService: ServiceWorkerUpdateService) { }

  async initApp(): Promise<any> {
    // set device identifier for services to use in headers
    Environment.DeviceIdentifier = await this.appStorage.getDeviceIdentifier();

    this.updateService.init();

    // console.log('initializing app...');
    // const response = await this.testService.configuration().toPromise();

    // AppConfig.isProduction = response.environment === CurrentEnvironment.Production;

    await this.authService.init();
  }
}
